<template lang="pug">
.main-wrapper.bancos-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Bancos</b>

    Dialog.dialogApagar(header='Remover Banco' :visible.sync='dialogApagar' :modal='true')
        p Deseja remover o banco <b>{{ dialogApagar_data.nm_banco }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-danger(v-else label='Remover' @click='remove()')

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
        .p-grid.p-fluid
            .p-col-12
                label.form-label Banco:
                .p-inputgroup
                    InputText(
                        placeholder='Banco'
                        @keyup.enter.native='() => !waiting && applyFilters()'
                        @input='(val) => !val && applyFilters()'
                        v-model='filters.nm_banco'
                    )
                    Button(icon='jam jam-search' @click='applyFilters()' :disabled='waiting')

    .ta-right.my-2
        Button(label='Adicionar' icon="jam jam-plus" @click="$router.push(`/bancos/salvar/`)")

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em")
                    Panel.panel-list.ta-center(:header="props.data.nm_banco" style='position: relative')
                        .ta-left
                            p <b>Código:</b> {{ props.data.ds_codigo }}
                            p <b>Banco:</b> {{ props.data.nm_banco }}
                            p <b>Sigla:</b> {{ props.data.ds_sigla }}
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/bancos/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Bancos')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list")
                Column(headerStyle='width: 12%;' field='ds_codigo' header='Código')
                    template(#body='props')
                        span(v-tooltip.top="props.data.ds_codigo" style='cursor: pointer;') {{ props.data.ds_codigo }}

                Column(headerStyle='width: 54%;' field='nm_banco' header='Banco')
                    template(#body='props')
                        span(v-tooltip.top="props.data.nm_banco" style='cursor: pointer;') {{ props.data.nm_banco }}

                Column(headerStyle='width: 12%;' field='ds_sigla' header='Sigla')
                    template(#body='props')
                        span(v-tooltip.top="props.data.ds_sigla" style='cursor: pointer;') {{ props.data.ds_sigla }}

                Column(headerStyle='width: 12%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/bancos/salvar/${ props.data.id }/`)"
                            )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar = true; dialogApagar_data = props.data"
                            )
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .bancos-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'

    import { Bancos } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import axios from "axios"

    const _ = require('lodash')

    export default {
        created () {
            this.applyFilters()
        },
        components: {
            ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown, MultiSelect,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                cancelToken: null,
                params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 300, { leading: true }),
                filters: {
                    nm_banco: '',
                },
                order: '-id',
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0,
                },
            }
        },
        methods: {
            getList (params) {
                if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                this.waiting = true
                return Bancos.findAll(params, this.cancelToken.token).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    this.cancelToken = null
                })
            },
            applyFilters(page, force) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, order: this.order }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })

                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params) && !force) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            remove () {
                this.waitingApagar = true
                Bancos.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Banco REMOVIDO com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters(1, true)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>
